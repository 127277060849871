<template>
    <w-dialog max-width="400px" :title="$t('application.access_renewal', {application: application?.title})" :value="value" @close="$emit('close', false)">
        <w-text-info class="mb-2" :text="$t('application.access_renewal_info')" />
        <w-form-builder v-model="formValue" :fields="formFields" :validation.sync="formValidation" @submit="save" />
        <template v-slot:actions>
			<v-spacer />
			<w-btn-save :disabled="!formValidation" :loading="loading" @click="save" />
        </template>
    </w-dialog>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
    name: 'FixAccountingFirmApplicationDialog',
	mixins: [ApplicationsModuleGuard],
    props: {
        application: {
            default: null,
            required: false,
            type: Object
        },
        fields: {
            default: () => ([]),
            required: false,
            type: Array
        },
        value: {
            default: true,
            required: false,
            type: Boolean
        }
    },
    data: function () {
        return {
            formFields: [],
            formValidation: false,
            formValue: {},
            loading: false
        }
    },
    watch: {
        value: {
            handler: function (dialogValue) {
                if (dialogValue) {
                    this.openForm()
                }
            }
        }
    },
    methods: {
        openForm: function () {
            this.formFields = []
            this.formValue = {}
			
            this.fields
                .filter(({auto}) => !auto)
                .forEach(field => {
                    const formField = {... field}
                    formField.label = this.$t(field.label)
					if (field.text) {
						formField.text = this.$t(field.text)
					}
                    if (field.value) {
                        this.formValue[field.name] = field.value
                    }

                    this.formFields.push(formField)
                })
        },
        save: function () {
            this.loading = true

            this.service.updateAccountingFirmApplication(this.accountingFirmId, this.application.id, this.formValue)
                .then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('application.events.accounting_firm_application_fixed'))
                    this.$emit('close', true)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>